import React, { memo } from "react";
import PropTypes from "prop-types";

import styles from "../../styles/work-card.module.scss";

const Caption = ({ isVisible, title, address, className }) => (
  <figcaption
    className={`${styles.caption} ${
      isVisible ? "" : styles.isInvisible
    } ${className}`}
  >
    <div>
      <span className="title is-4">{title}</span>
      <p>{address}</p>
    </div>
  </figcaption>
);

Caption.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string,
  className: PropTypes.string
};

Caption.defaultProps = {
  address: "",
  className: ""
};

export default memo(Caption);
