import React from "react";
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import WorkCard from "../components/work-card";
import Tiles from "../components/tiles";

function PortfolioPage({ data }) {
  const items = data.portfolio.edges.map(
    ({ node: { fields, frontmatter } }) => (
      <WorkCard
        image={frontmatter.mainImage.childImageSharp}
        title={frontmatter.title}
        address={`${frontmatter.street}, ${frontmatter.locality}`}
        key={fields.slug}
        onClick={() => navigate(`/portfolio/${fields.slug}`)}
      />
    )
  );

  return (
    <Layout title="Portfolio">
      <section className="section">
        <div className="container">
          <h1 className="title is-2">Portfolio</h1>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <Tiles items={items} />
        </div>
      </section>
    </Layout>
  );
}

PortfolioPage.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object
  }).isRequired
};

export const query = graphql`
  query {
    portfolio: allMarkdownRemark(
      filter: { frontmatter: { key: { eq: "portfolio" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            client
            locality
            street
            mainImage {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PortfolioPage;
