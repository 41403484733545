import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import Caption from "./caption";
import styles from "../../styles/work-card.module.scss";

const WorkCard = ({ image, title, address, onClick, className }) => {
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const toggleInfo = () => setIsInfoVisible(!isInfoVisible);
  return (
    <figure
      className={`image ${styles.container} ${className}`}
      onMouseEnter={toggleInfo}
      onMouseLeave={toggleInfo}
      onClick={onClick}
    >
      <Img className={styles.image} fluid={image.fluid} alt={title} />
      <Caption isVisible={isInfoVisible} title={title} address={address} />
    </figure>
  );
};

WorkCard.propTypes = {
  image: PropTypes.shape({ fluid: PropTypes.object }).isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
};

WorkCard.defaultProps = {
  address: "",
  onClick: () => {},
  className: ""
};

export default memo(WorkCard);
